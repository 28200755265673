import Image from "@/shared/components/Image/new"
import styles from "./styles.module.css";
import { formatAuthorNames } from '@/shared/functions/formatAthorNames'
import { EnVivo } from "@/shared/components/enVivo";

const NotasDePortada = (props) => {
    const CLIPPING = "3_2_300x200";
    const { content } = props;
    
    return (
        <div className={`${styles["parent"]}`}>
            {content &&
                content.map((element, index) => {
                    const { subtitle, url, id, title, authors, metadata} = element;
                    if (element.images && element.images[0].value) {
                        element = {
                            images: [
                                element.images[0] &&
                                element.images[0].value && {
                                    clippings: element.images[0].value.clippings || [],
                                    title: element.images[0].value.title || "",
                                    _id: element.images[0]._id,
                                },
                            ],
                        };
                    };
                    
                    let authorsList = []
                    if (authors != undefined && authors?.length) {
			            const authorName = formatAuthorNames(authors[0].name);
                        authors.length > 1 ? authors.slice(0, 2).map(element => {

				            const elementName = formatAuthorNames(element.name);
                            return authorsList.push(element.slug ? `<a href=/autor/${element.slug}>${elementName}</a>` : elementName)

                        }) 
                        
                        : authorsList.push(authors[0].slug ? `<a href=/autor/${authors[0].slug}>${authorName}</a>` : authorName)
                    }
                    const parsedAuthors = authorsList.join(' - ');

                    return (
                        <div key={'NotasDePortada' + id + index} className={`${styles["grid-item"]}`}>
                            <div className={`${styles['nota-link']}`}>
                                { metadata && metadata.sponsored ? (
                                    <div className={`${styles['sponsored']}`}>
                                        <a href={url}>
                                            <Image
                                                element={element}
                                                clipping={CLIPPING}
                                                width={300}
                                                height={200}
                                                className={styles["img"]}
                                            />
                                            <span 
                                                className={styles["badge"]} 
                                                data-testid='patrocinado'
                                            >
                                                patrocinado
                                            </span>
                                        </a>
                                    </div>
                                ) : (
                                    <a href={url}>
                                       <div className={`${styles["img-container"]}`}>
                                        <Image
                                                element={element}
                                                clipping={CLIPPING}
                                                width={300}
                                                height={200}
                                                className={styles["img"]}
                                            />
                                        {metadata && metadata.typeWeb && metadata.typeWeb === "live_blog" && <EnVivo data-testid='envivo'/>}
                                       </div>
                                    </a>
                                )}
                                <h3>
                                    <a href={url}>
                                        <span className={`${styles['highlighted-text']}`}>
                                            {subtitle}{" "}
                                        </span>
                                        {title}
                                    </a>
                                </h3>
                            </div>

                            { parsedAuthors && (
                                <div 
                                    className={`${styles["note-author"]}`} 
                                    dangerouslySetInnerHTML={{ 
                                        __html: 'Por ' + parsedAuthors 
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

export default NotasDePortada;