import styles from './styles.module.css';

export const EnVivo = ({title, classStyle}) => {

    return (
        <span className={classStyle}>{title}</span>
    );
}

EnVivo.defaultProps = {
    title: 'En Vivo',
    classStyle: styles["envivo"]
}
